import { Sign } from "../types";
import { alphabetSigns } from "./signs/alphabet";
import { numberSigns } from "./signs/numbers";
import { basicSigns } from "./signs/basics";
import { emotionSigns } from "./signs/emotions";
import { courses } from "./courses";
import { courseSigns } from "./courseSigns";

export const signs: Sign[] = [
  ...alphabetSigns,
  ...numberSigns,
  ...basicSigns,
  ...emotionSigns,
];

export { courses, courseSigns };

export const getSignsForCourse = (courseId: string) => {
  return courseSigns
    .filter(cs => cs.courseId === courseId)
    .sort((a, b) => a.orderIndex - b.orderIndex)
    .map(s => signs.find(sign => sign.id === s.signId)!);
};

// For backward compatibility with existing components
export const mockCourses = courses.map(course => ({
  ...course,
  cards: getSignsForCourse(course.id).map(sign => ({
    id: sign.id,
    title: sign.title,
    imageUrl: sign.answerImageUrl,
    questionImage: sign.questionImageUrl,
    dictionaryUrl: sign.dictionaryUrl,
  })),
}));