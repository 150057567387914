import { Sign } from "../../types";

export const basicSigns: Sign[] = [
  {
    id: "hello",
    title: "Hello",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/1301/hello-1301-high_resolution.png",
    questionImageUrl: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6",
    dictionaryUrl: "https://www.nzsl.nz/signs/1301",
  },
  {
    id: "thank-you",
    title: "Thank You",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/1015/thank-1015-high_resolution.png",
    questionImageUrl: "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158",
    dictionaryUrl: "https://www.nzsl.nz/signs/1015",
  },
];