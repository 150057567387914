import { Button } from "@/components/ui/button";

interface StudyControlsProps {
  onPrevious: () => void;
  onNext: () => void;
  isFirstCard: boolean;
  isLastCard: boolean;
}

export const StudyControls = ({
  onPrevious,
  onNext,
  isFirstCard,
  isLastCard
}: StudyControlsProps) => {
  return (
    <div className="flex justify-between items-center mt-4">
      <Button
        variant="outline"
        onClick={onPrevious}
        disabled={isFirstCard}
      >
        Previous
      </Button>
      <Button
        onClick={onNext}
        disabled={isLastCard}
      >
        Next
      </Button>
    </div>
  );
};