import { Progress } from "@/components/ui/progress";
import { LearningMode } from "../../types";

interface StudyProgressProps {
  currentIndex: number;
  total: number;
  mode: LearningMode;
}

export const StudyProgress = ({ currentIndex, total, mode }: StudyProgressProps) => {
  const progress = ((currentIndex + 1) / total) * 100;

  return (
    <div className="mb-4">
      <Progress value={progress} className="h-2" />
      <p className="text-center mt-2">
        {mode === "practice" ? "Sign" : "Question"} {currentIndex + 1} of {total}
      </p>
    </div>
  );
};