import { useParams, useNavigate } from "react-router-dom";
import { mockCourses } from "@/data/mockData";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { LearningMode } from "@/types";
import { Brain, PencilRuler, Eye } from "lucide-react";

const Course = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const course = mockCourses.find((c) => c.id === courseId);

  if (!course) {
    return <div>Course not found</div>;
  }

  const startLearning = (mode: LearningMode) => {
    navigate(`/course/${courseId}/study?mode=${mode}`);
  };

  return (
    <div className="container py-8">
      <div className="flex flex-col gap-4 mb-8">
        <h1 className="text-4xl font-bold">{course.title}</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Button 
            onClick={() => startLearning("practice")}
            className="flex items-center gap-2"
          >
            <Brain className="w-4 h-4" />
            Practice
          </Button>
          <Button 
            variant="secondary"
            onClick={() => startLearning("signThis")}
            className="flex items-center gap-2"
          >
            <PencilRuler className="w-4 h-4" />
            Test: Sign This
          </Button>
          <Button 
            variant="secondary"
            onClick={() => startLearning("whatsThisSign")}
            className="flex items-center gap-2"
          >
            <Eye className="w-4 h-4" />
            Test: What's This Sign?
          </Button>
        </div>
      </div>
      
      <p className="text-lg text-muted-foreground mb-8">{course.description}</p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {course.cards.map((card) => (
          <Card key={card.id}>
            <CardHeader>
              <CardTitle>{card.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <img
                src={card.imageUrl}
                alt={card.title}
                className="w-full h-48 object-contain rounded-md"
              />
              <a
                href={card.dictionaryUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline block mt-4"
              >
                View in NZSL Dictionary
              </a>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Course;