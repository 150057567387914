import { useState, useEffect } from "react";
import { Auth as SupabaseAuth } from "@supabase/auth-ui-react";
import { supabase } from "@/integrations/supabase/client";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { AuthChangeEvent, Session } from "@supabase/supabase-js";

export const AuthForm = () => {
  const [authError, setAuthError] = useState<string | null>(null);

  // Subscribe to auth state changes to catch errors and handle sign out
  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event: AuthChangeEvent, session: Session | null) => {
      if (event === "SIGNED_OUT") {
        setAuthError(null);
      } else if (!session && event === "SIGNED_IN") {
        // Handle potential sign in errors
        setAuthError("An error occurred during authentication");
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div>
      <SupabaseAuth 
        supabaseClient={supabase} 
        appearance={{ 
          theme: ThemeSupa,
          style: {
            button: {
              background: '#6366F1',
              color: 'white',
            },
            anchor: {
              color: '#6366F1',
            },
          },
        }}
        localization={{
          variables: {
            sign_in: {
              email_input_placeholder: "Your email address",
              password_input_placeholder: "Your password",
              email_label: "Email",
              password_label: "Password",
              button_label: "Sign in",
              loading_button_label: "Signing in ...",
            },
          },
        }}
      />
      {authError && (
        <div className="text-red-500 text-sm mt-2 text-center">
          {authError}
        </div>
      )}
    </div>
  );
};