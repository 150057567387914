import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { Button } from "./ui/button";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";
import { Auth } from "./Auth";
import { toast } from "sonner";
import { Home, BookOpen } from "lucide-react";

export const Header = () => {
  const session = useSession();
  const supabase = useSupabaseClient();
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const location = useLocation();

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    toast.success("Successfully signed out!");
  };

  return (
    <header className="border-b">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-8">
            <Link to="/" className="text-2xl font-bold text-primary">
              NZSL Learning
            </Link>
            <nav className="hidden md:flex items-center gap-6">
              <Link
                to="/"
                className="flex items-center gap-2 text-muted-foreground hover:text-primary transition-colors"
              >
                <Home className="w-4 h-4" />
                Home
              </Link>
              <Link
                to="/courses"
                className="flex items-center gap-2 text-muted-foreground hover:text-primary transition-colors"
              >
                <BookOpen className="w-4 h-4" />
                All Courses
              </Link>
            </nav>
          </div>
          <div className="flex gap-4">
            {session ? (
              <Button variant="outline" onClick={handleSignOut}>
                Sign Out
              </Button>
            ) : (
              <Button onClick={() => setShowAuthDialog(true)}>
                Sign In / Register
              </Button>
            )}
          </div>
        </div>
      </div>

      <Dialog open={showAuthDialog} onOpenChange={setShowAuthDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Sign In or Create Account</DialogTitle>
            <DialogDescription>
              Access all courses and track your progress
            </DialogDescription>
          </DialogHeader>
          <Auth onClose={() => setShowAuthDialog(false)} />
        </DialogContent>
      </Dialog>
    </header>
  );
};