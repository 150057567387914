import { FlashCard, LearningMode, ScoreOption } from "../../types";
import { Button } from "@/components/ui/button";

interface StudyCardProps {
  card: FlashCard;
  mode: LearningMode;
  showAnswer: boolean;
  onShowAnswer: () => void;
  onScore?: (score: ScoreOption) => void;
}

export const StudyCard = ({ 
  card, 
  mode, 
  showAnswer, 
  onShowAnswer, 
  onScore 
}: StudyCardProps) => {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Question Section */}
        <div>
          {mode === "practice" ? (
            <>
              <img
                src={card.imageUrl}
                alt="Sign"
                className="w-full h-64 object-contain rounded-lg mb-4"
              />
              <img
                src={card.questionImage}
                alt="Question"
                className="w-full h-64 object-contain rounded-lg"
              />
            </>
          ) : mode === "signThis" ? (
            <>
              <img
                src={card.questionImage}
                alt="Question"
                className="w-full h-64 object-contain rounded-lg mb-4"
              />
              <h3 className="text-2xl font-bold text-center">{card.title}</h3>
            </>
          ) : (
            <img
              src={card.imageUrl}
              alt="Sign"
              className="w-full h-64 object-contain rounded-lg mb-4"
            />
          )}
        </div>
        
        {/* Answer Section */}
        {(mode === "practice" || showAnswer) && (
          <div className="animate-fade-in">
            {mode === "signThis" && showAnswer && (
              <img
                src={card.imageUrl}
                alt={card.title}
                className="w-full h-64 object-contain rounded-lg"
              />
            )}
            {mode === "whatsThisSign" && showAnswer && (
              <>
                <h3 className="text-2xl font-bold mb-4">{card.title}</h3>
                <img
                  src={card.questionImage}
                  alt="Question"
                  className="w-full h-64 object-contain rounded-lg"
                />
              </>
            )}
            {(mode === "practice" || showAnswer) && (
              <>
                <h3 className="text-2xl font-bold mt-4">{card.title}</h3>
                <a
                  href={card.dictionaryUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary hover:underline block mt-2"
                >
                  View in NZSL Dictionary
                </a>
              </>
            )}
          </div>
        )}
      </div>

      {/* Test Mode Controls */}
      {mode !== "practice" && !showAnswer && (
        <Button className="w-full mt-4" onClick={onShowAnswer}>
          Show Answer
        </Button>
      )}

      {/* Scoring Buttons */}
      {mode !== "practice" && showAnswer && onScore && (
        <div className="grid grid-cols-3 gap-4 mt-4">
          <Button
            variant="outline"
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={() => onScore("incorrect")}
          >
            I got it wrong
          </Button>
          <Button
            variant="outline"
            className="bg-warning text-warning-foreground hover:bg-warning/90"
            onClick={() => onScore("close")}
          >
            Almost had it
          </Button>
          <Button
            variant="outline"
            className="bg-success text-success-foreground hover:bg-success/90"
            onClick={() => onScore("correct")}
          >
            I got it perfect
          </Button>
        </div>
      )}
    </div>
  );
};