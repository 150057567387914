import { CourseSign } from "../types";

export const courseSigns: CourseSign[] = [
  // Numbers course signs
  { courseId: "numbers-1-20", signId: "zero", orderIndex: 0 },
  { courseId: "numbers-1-20", signId: "one", orderIndex: 1 },
  { courseId: "numbers-1-20", signId: "two", orderIndex: 2 },
  { courseId: "numbers-1-20", signId: "three", orderIndex: 3 },
  { courseId: "numbers-1-20", signId: "four", orderIndex: 4 },
  { courseId: "numbers-1-20", signId: "five", orderIndex: 5 },
  { courseId: "numbers-1-20", signId: "six", orderIndex: 6 },
  { courseId: "numbers-1-20", signId: "seven", orderIndex: 7 },
  { courseId: "numbers-1-20", signId: "eight", orderIndex: 8 },
  { courseId: "numbers-1-20", signId: "nine", orderIndex: 9 },
  // Signs without correct Question Images in the mock data
  // { courseId: "numbers-1-20", signId: "ten", orderIndex: 10 },
  // { courseId: "numbers-1-20", signId: "twelve", orderIndex: 12 },
  // { courseId: "numbers-1-20", signId: "eleven", orderIndex: 11 },
  // { courseId: "numbers-1-20", signId: "thirteen", orderIndex: 13 },
  // { courseId: "numbers-1-20", signId: "fourteen", orderIndex: 14 },
  // { courseId: "numbers-1-20", signId: "fifteen", orderIndex: 15 },
  // { courseId: "numbers-1-20", signId: "sixteen", orderIndex: 16 },
  // { courseId: "numbers-1-20", signId: "seventeen", orderIndex: 17 },
  // { courseId: "numbers-1-20", signId: "eighteen", orderIndex: 18 },
  // { courseId: "numbers-1-20", signId: "nineteen", orderIndex: 19 },
  // { courseId: "numbers-1-20", signId: "twenty", orderIndex: 20 },
  
  // Alphabet course signs
  { courseId: "alphabet-a-z", signId: "a", orderIndex: 0 },
  { courseId: "alphabet-a-z", signId: "b", orderIndex: 1 },
  { courseId: "alphabet-a-z", signId: "c", orderIndex: 2 },
  { courseId: "alphabet-a-z", signId: "d", orderIndex: 3 },
  { courseId: "alphabet-a-z", signId: "e", orderIndex: 4 },
  { courseId: "alphabet-a-z", signId: "f", orderIndex: 5 },
  { courseId: "alphabet-a-z", signId: "g", orderIndex: 6 },
  { courseId: "alphabet-a-z", signId: "h", orderIndex: 7 },
  { courseId: "alphabet-a-z", signId: "i", orderIndex: 8 },
  { courseId: "alphabet-a-z", signId: "j", orderIndex: 9 },
  { courseId: "alphabet-a-z", signId: "k", orderIndex: 10 },
  { courseId: "alphabet-a-z", signId: "l", orderIndex: 11 },
  { courseId: "alphabet-a-z", signId: "m", orderIndex: 12 },
  { courseId: "alphabet-a-z", signId: "n", orderIndex: 13 },
  { courseId: "alphabet-a-z", signId: "o", orderIndex: 14 },
  { courseId: "alphabet-a-z", signId: "p", orderIndex: 15 },
  { courseId: "alphabet-a-z", signId: "q", orderIndex: 16 },
  { courseId: "alphabet-a-z", signId: "r", orderIndex: 17 },
  { courseId: "alphabet-a-z", signId: "s", orderIndex: 18 },
  { courseId: "alphabet-a-z", signId: "t", orderIndex: 19 },
  { courseId: "alphabet-a-z", signId: "u", orderIndex: 20 },
  { courseId: "alphabet-a-z", signId: "v", orderIndex: 21 },
  { courseId: "alphabet-a-z", signId: "w", orderIndex: 22 },
  { courseId: "alphabet-a-z", signId: "x", orderIndex: 23 },
  { courseId: "alphabet-a-z", signId: "y", orderIndex: 24 },
  { courseId: "alphabet-a-z", signId: "z", orderIndex: 25 },
  
  // Emotions course signs
  { courseId: "emotions", signId: "happy", orderIndex: 0 },
  { courseId: "emotions", signId: "sad", orderIndex: 1 },

  // Basics course signs
  { courseId: "basics", signId: "hello", orderIndex: 0 },
  { courseId: "basics", signId: "thank-you", orderIndex: 1 },
];
