import { Course } from "../types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { Lock } from "lucide-react";
import { useSession } from "@supabase/auth-helpers-react";
import { cn } from "@/lib/utils";
import { getSignsForCourse } from "@/data/mockData";

interface CourseCardProps {
  course: Course;
}

export const CourseCard = ({ course }: CourseCardProps) => {
  const navigate = useNavigate();
  const session = useSession();
  const isAccessible = course.isFree || session;
  const signs = getSignsForCourse(course.id);

  return (
    <Card 
      className={cn(
        "relative transition-shadow duration-300",
        isAccessible && "cursor-pointer hover:shadow-lg"
      )}
      onClick={() => isAccessible && navigate(`/course/${course.id}`)}
    >
      {!isAccessible && (
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg z-10">
          <Lock className="w-8 h-8 text-white" />
        </div>
      )}
      <CardHeader>
        <CardTitle className="text-xl font-bold">
          {course.title}
          {course.isFree && (
            <span className="ml-2 text-sm font-normal text-green-600">Free</span>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <img 
          src={course.answerImageUrl} 
          alt={course.title} 
          className="w-full h-48 object-cover rounded-md mb-4"
        />
        <p className="text-muted-foreground">{course.description}</p>
        <p className="text-sm text-primary mt-2">{signs.length} cards</p>
      </CardContent>
    </Card>
  );
}