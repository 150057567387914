import { TestResult } from "../../types";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface ResultsProps {
  results: TestResult[];
  courseId: string;
  onRetry: () => void;
}

export const Results = ({ results, courseId, onRetry }: ResultsProps) => {
  const navigate = useNavigate();
  const correctCount = results.filter((r) => r.score === "correct").length;
  const closeCount = results.filter((r) => r.score === "close").length;
  const incorrectCount = results.filter((r) => r.score === "incorrect").length;

  return (
    <div className="text-center p-8">
      <h2 className="text-2xl font-bold mb-4">Test Complete!</h2>
      <div className="mb-8">
        <p>Perfect: {correctCount}</p>
        <p>Almost: {closeCount}</p>
        <p>Wrong: {incorrectCount}</p>
      </div>
      <div className="flex gap-4 justify-center">
        <Button onClick={() => navigate(`/course/${courseId}`)}>
          Back to Course
        </Button>
        <Button variant="secondary" onClick={onRetry}>
          Try Again
        </Button>
      </div>
    </div>
  );
};