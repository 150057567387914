import { useEffect } from "react";
import { toast } from "sonner";
import { useSessionContext } from "@supabase/auth-helpers-react";
import { AuthForm } from "./AuthForm";

interface AuthProps {
  onClose: () => void;
}

export const Auth = ({ onClose }: AuthProps) => {
  const { session } = useSessionContext();

  // Handle successful login
  useEffect(() => {
    if (session) {
      toast.success("Successfully logged in!");
      onClose();
    }
  }, [session, onClose]);

  return <AuthForm />;
};