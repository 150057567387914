import { Sign } from "../../types";

export const emotionSigns: Sign[] = [
  {
    id: "happy",
    title: "Happy",
    answerImageUrl: "https://images.unsplash.com/photo-1649972904349-6e44c42644a7",
    questionImageUrl: "https://images.unsplash.com/photo-1485827404703-89b55fcc595e",
    dictionaryUrl: "https://nzsl.nz/signs/1644",
  },
  {
    id: "sad",
    title: "Sad",
    answerImageUrl: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6",
    questionImageUrl: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d",
    dictionaryUrl: "https://nzsl.nz/signs/4396",
  },
];