import { Course, LearningMode } from "../types";
import { StudyProgress } from "./study/StudyProgress";
import { StudyCard } from "./study/StudyCard";
import { StudyControls } from "./study/StudyControls";
import { Results } from "./study/Results";
import { useStudyState } from "@/hooks/useStudyState";

interface StudyProps {
  course: Course;
  mode: LearningMode;
}

export const Study = ({ course, mode }: StudyProps) => {
  const {
    currentCardIndex,
    showAnswer,
    setShowAnswer,
    results,
    cards,
    handleNext,
    handlePrevious,
    handleScore,
    handleRetry
  } = useStudyState(course.id, mode);

  const currentCard = cards[currentCardIndex];

  // Show results when we've gone through all cards
  if (currentCardIndex >= cards.length) {
    return (
      <Results 
        results={results} 
        courseId={course.id} 
        onRetry={handleRetry} 
      />
    );
  }

  return (
    <div className="max-w-3xl mx-auto">
      <StudyProgress
        currentIndex={currentCardIndex}
        total={cards.length}
        mode={mode}
      />

      <StudyCard
        card={currentCard}
        mode={mode}
        showAnswer={mode === "practice" || showAnswer}
        onShowAnswer={() => setShowAnswer(true)}
        onScore={mode !== "practice" ? handleScore : undefined}
      />

      {mode === "practice" && (
        <StudyControls
          onPrevious={handlePrevious}
          onNext={handleNext}
          isFirstCard={currentCardIndex === 0}
          isLastCard={currentCardIndex === cards.length - 1}
        />
      )}
    </div>
  );
};