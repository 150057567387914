import { mockCourses } from "@/data/mockData";
import { CourseCard } from "@/components/CourseCard";

const Index = () => {
  const freeCourses = mockCourses.filter(course => course.isFree);
  const paidCourses = mockCourses.filter(course => !course.isFree);

  return (
    <div className="container py-8">
      <section className="mb-12">
        <h2 className="text-3xl font-bold mb-6">Free Courses</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {freeCourses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </div>
      </section>

      <section>
        <h2 className="text-3xl font-bold mb-6">Premium Courses</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {paidCourses.map((course) => (
            <CourseCard key={course.id} course={course} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Index;