import { useState, useEffect } from "react";
import { FlashCard, LearningMode, TestResult } from "../types";
import { shuffle } from "lodash";
import { getSignsForCourse } from "@/data/mockData";

export const useStudyState = (courseId: string, mode: LearningMode) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [results, setResults] = useState<TestResult[]>([]);
  
  // Get signs for the course and convert them to FlashCard format
  const signs = getSignsForCourse(courseId);
  const [cards, setCards] = useState(signs.map(sign => ({
    id: sign.id,
    title: sign.title,
    imageUrl: sign.answerImageUrl,
    questionImage: sign.questionImageUrl,
    dictionaryUrl: sign.dictionaryUrl
  })));

  // Shuffle cards only once when component mounts and only in test modes
  useEffect(() => {
    if (mode === "signThis" || mode === "whatsThisSign") {
      setCards(prevCards => shuffle([...prevCards]));
    }
  }, [mode]);

  const handleNext = () => {
    if (currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      if (mode === "signThis" || mode === "whatsThisSign") {
        setShowAnswer(false);
      }
    }
  };

  const handlePrevious = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
  };

  const handleScore = (score: "correct" | "close" | "incorrect") => {
    const newResults = [...results, { cardId: cards[currentCardIndex].id, score }];
    setResults(newResults);
    setShowAnswer(false);
    setCurrentCardIndex(currentCardIndex + 1);
  };

  const handleRetry = () => {
    setCurrentCardIndex(0);
    setResults([]);
    setShowAnswer(false);
    // Reshuffle cards when retrying in test modes
    if (mode === "signThis" || mode === "whatsThisSign") {
      setCards(prevCards => shuffle([...prevCards]));
    }
  };

  return {
    currentCardIndex,
    showAnswer,
    setShowAnswer,
    results,
    cards,
    handleNext,
    handlePrevious,
    handleScore,
    handleRetry
  };
};