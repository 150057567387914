import { Sign } from "../../types";

export const alphabetSigns: Sign[] = [
  {
    id: "a",
    title: "A",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.A-6b2966afe72b02f5bc4121e3abcd8680e67a9ad6d06c458dad7d10b0f515d87d.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020081-845fa2192901?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/alphabet",
  },
  {
    id: "b",
    title: "B",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.B_closed-3931a076b0609c3224d662050620afc7102fe7de132e6cb542a6ce98e48664fa.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020186-215fe7f68d9e?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/alphabet",
  },
  {
    id: "c",
    title: "C",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.C_full-428e0cb1eba10413c17af5b72300aab137c7e74629e4890edb29bf0606f70e38.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020104-6a0e55a177c0?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/alphabet",
  },
  {
    id: "d",
    title: "D",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.D-52987742d09671cc03e9e695d717cf832d5030b41f100874a5907b4a7f4c87c7.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020079-ddc759b4fee3?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/alphabet",
  },
  {
    id: "e",
    title: "E",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.E-d0aceec98a3e2ef154ba2dba79356b012605b1f54e66235750d2ce739da26562.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020098-1c103673ee04?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5005",
  },
  {
    id: "f",
    title: "F",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.F-3729f17b82e57f4fb40b699a01b2f66bb9a1b8b9777da0bb92206c4997314c19.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020092-1dd9f23188f9?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5006",
  },
  {
    id: "g",
    title: "G",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.G-1827a1497dbc2d4af2fbcd5d540b7994ae3885d8cba40f1afed3ab05fa08bb19.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020141-651e7a1a9d47?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5007",
  },
  {
    id: "h",
    title: "H",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.H-a7b0c8d178218c44ef1b74fbbcc290bb4ef5f9e6eccc684200c6bdd5f9b34041.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020095-3fd2f094dec0?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5008",
  },
  {
    id: "i",
    title: "I",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.I-cbfa0bdacca5a7af08d0de58ac99b4ff62875bd1b648ecfa4736138928570355.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020125-d5ff15489995?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5009",
  },
  {
    id: "j",
    title: "J",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.J-c160dc6ccdb69ab13c3d275654ad0a25d0f3275a34025427c7403b2f5958ef0d.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020123-bcb9fd84e0a4?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5010",
  },
  {
    id: "k",
    title: "K",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.K-c6cc18b5c9780584a3216f15db21070d42dbc0ef315b5cce4d09b4e88c8dbdf4.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020219-cdd53467966a?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5011",
  },
  {
    id: "l",
    title: "L",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.L-14266555a956f088438b8e8bb5cd11d66066d4a53029ace77d38bdd94b099656.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020117-36709f5927d7?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5012",
  },
  {
    id: "m",
    title: "M",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.M-4793968f52375307d8a2846f84ab6520b4d599bbc831232877c6c68b866f8ed3.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020147-6618a1405c94?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5013",
  },
  {
    id: "n",
    title: "N",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.N-ac7a49eb868c1b64d167454fb698758e0f970f24b175ee5abe5b2ef5561a66d2.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020116-68129bb81549?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5014",
  },
  {
    id: "o",
    title: "O",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.O-b8aaf82755e0d547d32b38f8ba3b0e8bb91f37a02155c4737558e9ebe27906e2.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020143-d4c4926fef63?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5015",
  },
  {
    id: "p",
    title: "P",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.P_closed-5925d113cbea1f87933a1a23269608381a49fc15655414bb15882388eaaf7bbc.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020139-d1bdef4f449e?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5016",
  },
  {
    id: "q",
    title: "Q",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.Q-33afbc2ec2d2d51f7436f10cfb23b30f641f0da1189bc3fbacb89776b65908dd.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020153-09484d0547f7?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5017",
  },
  {
    id: "r",
    title: "R",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.R-ebba3157de37486d6586c4a28f53673f5cd51746d9a9a63af314c8845d92758e.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020144-9211e808adef?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5018",
  },
  {
    id: "s",
    title: "S",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.S-7cdd566db8835cc364034536fb6afa77937d5eb4fda52d9f693792b81917bb4b.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020152-3a6a6c396a52?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5019",
  },
  {
    id: "t",
    title: "T",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.T-b13005b113aca4e0e87e75125a9ed50aef878d34d1bea9f5b4ab13674e29a438.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020147-37523f89d99b?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5020",
  },
  {
    id: "u",
    title: "U",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.U-f7e86bffb0463ef124f4a459e3be6ab0a0628aeb2712d19a0a34c4fdb3571736.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020165-5acaba80aa9b?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5021",
  },
  {
    id: "v",
    title: "V",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.V-e2eb5c5f1a18167482df75a777809e969745b50fdaa955a3b638ffe32da03554.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020163-630e073c0692?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5022",
  },
  {
    id: "w",
    title: "W",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.W-fa01627d37629b5512634c86d852a1364843c0cd83ccb9ec50e5211fcd9738fa.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020152-197fa5a8cab5?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5023",
  },
  {
    id: "x",
    title: "X",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.X-719694fc849a08c01862be6e219005d7d576cd15436431733c80801c401099a6.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020157-ed19bcb77ee2?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5024",
  },
  {
    id: "y",
    title: "Y",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.Y-cae93d008ac9716bc1e0781e29c1cfcde0fa22eb76ae7de9219e42434b609512.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020182-0c4375380b14?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5025",
  },
  {
    id: "z",
    title: "Z",
    answerImageUrl: "https://www.nzsl.nz/assets/alphabet/82/Alphabet.Z-ad6bb997cd0ad117540dbc728df0c3e607a94455329ceece89b1b51e2e5dfcee.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020183-1c3ac1ec4227?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/5026",
  },
];
