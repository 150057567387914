import { Sign } from "../../types";

export const numberSigns: Sign[] = [
  {
    id: "zero",
    title: "Zero",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5655/zero-5655-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020244-9da445234413?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/signs/5655",
  },
  {
    id: "one",
    title: "One",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5607/one-5607-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020170-5ba458728767?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/signs/5607",
  },
  {
    id: "two",
    title: "Two",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5617/two-5617-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020184-05493a907658?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/signs/5617",
  },
  {
    id: "three",
    title: "Three",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5619/three-5619-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020185-b30663507449?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/signs/5619",
  },
  {
    id: "four",
    title: "Four",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5620/four-5620-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020188-fce4232ea469?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/signs/5620",
  },
  {
    id: "five",
    title: "Five",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5621/five-5621-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020152-3a6a6c396a52?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://www.nzsl.nz/signs/5621",
  },
  {
    id: "six",
    title: "Six",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5622/six-5622-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020189-6f9044799aaf?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/4400",
  },
  {
    id: "seven",
    title: "Seven",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5623/seven-5623-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020344-5a8c37ec1c3c?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/4401",
  },
  {
    id: "eight",
    title: "Eight",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5624/eight-5624-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020352-a1573637e73f?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/4402",
  },
  {
    id: "nine",
    title: "Nine",
    answerImageUrl: "https://nzsl-signbank-media-production.s3.amazonaws.com/glossvideo/5696/nine-5696-high_resolution.png",
    questionImageUrl: "https://plus.unsplash.com/premium_photo-1670044020218-e904a50abfda?q=80&w=3500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    dictionaryUrl: "https://nzsl.nz/signs/4403",
  },
  {
    id: "ten",
    title: "Ten",
    answerImageUrl: "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4404",
  },
  {
    id: "eleven",
    title: "Eleven",
    answerImageUrl: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4405",
  },
  {
    id: "twelve",
    title: "Twelve",
    answerImageUrl: "https://images.unsplash.com/photo-1649972904349-6e44c42644a7",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4406",
  },
  {
    id: "thirteen",
    title: "Thirteen",
    answerImageUrl: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4407",
  },
  {
    id: "fourteen",
    title: "Fourteen",
    answerImageUrl: "https://images.unsplash.com/photo-1485827404703-89b55fcc595e",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4408",
  },
  {
    id: "fifteen",
    title: "Fifteen",
    answerImageUrl: "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4409",
  },
  {
    id: "sixteen",
    title: "Sixteen",
    answerImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4410",
  },
  {
    id: "seventeen",
    title: "Seventeen",
    answerImageUrl: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4411",
  },
  {
    id: "eighteen",
    title: "Eighteen",
    answerImageUrl: "https://images.unsplash.com/photo-1649972904349-6e44c42644a7",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4412",
  },
  {
    id: "nineteen",
    title: "Nineteen",
    answerImageUrl: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4413",
  },
  {
    id: "twenty",
    title: "Twenty",
    answerImageUrl: "https://images.unsplash.com/photo-1485827404703-89b55fcc595e",
    questionImageUrl: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b",
    dictionaryUrl: "https://nzsl.nz/signs/4414",
  },
];
